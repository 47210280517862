import React from "react"
import { HydraAdmin } from "@api-platform/admin"
import { CustomRoutes, defaultTheme } from "react-admin"
import cs from "ra-language-czech"
import polyglotI18nProvider from "ra-i18n-polyglot"
import { Route } from "react-router-dom"

import urlUtils from "../utils/url"
import * as authProvider from "../authProvider"
import dataProvider from "../dataProvider"
import Login from "./Login"
import Entities from "./Entities"
import Layout from "./Layout/Layout"
import MenuItems from "./Layout/MenuItems"
import NotFound from "./NotFound"
import AdminEntity from "../entities/admins"
import ListEntity from "./Entities/ListEntity"
import DataExport from "./DataExport"
import * as CUSTOM_ROUTES from "../constants/customRoutes"

const theme = {
  ...defaultTheme,
  sidebar: {
    width: "13rem",
    closedWidth: 55,
  },
}

function Admin() {
  const i18nProvider = polyglotI18nProvider(() => cs, "cs")
  const ENTRYPOINT = urlUtils.getApiUrl()

  return (
    <HydraAdmin
      locale="cs"
      menu={MenuItems}
      layout={Layout}
      theme={theme}
      entrypoint={ENTRYPOINT}
      authProvider={authProvider}
      dataProvider={dataProvider}
      loginPage={Login}
      i18nProvider={i18nProvider}
      catchAll={NotFound}
    >
      {Entities}
      <CustomRoutes>
        <Route path={CUSTOM_ROUTES.ADMINS} element={<ListEntity entity={AdminEntity} />} />
        <Route path={CUSTOM_ROUTES.DATA_EXPORT} element={<DataExport />} />
      </CustomRoutes>
    </HydraAdmin>
  )
}

export default Admin
